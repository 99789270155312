import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../src/fonts/Breathing Font/Breathing.ttf';
import '../src/fonts/Breathing Font/Breathing.otf';

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<App />
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);
