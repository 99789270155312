import React from 'react';
import {
	Container,
	Card,
	Row,
	Col,
	Image,
	ListGroup,
	Button,
} from 'react-bootstrap';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import Snowfall from 'react-snowfall';

// Import assets
import logo from '../../assets/LOGOcolor.png';
import headline from '../../assets/HLDlogo.png';

// Import components

import Faculty from '../../components/Faculty/Faculty';

import Quotes from '../../components/Testimonials/Quotes';

import WhatYouLearn from '../../components/WhatYouLearn/WhatYouLearn';

import SummerButton from '../../components/Modals/SummerButton';

import SummerModal from '../../components/Modals/SummerModal';
import Contact24 from '../Members/Contact24';

import Summer24 from '../../components/Summer/Summer24';

import ContactAgain from '../Contact/ContactAgain';
import SummerAccordionV2 from '../../components/Products/SummerAccordionV2';
import FallModal from '../../components/Modals/FallModal';

const Home = (props) => {
	return (
		<>
			<FallModal />
			<Container className='mt-2 pt-2 px-0 g-0'>
				<Container fluid className='px-0 g-0'>
					<Container>
						<div className='text-end'>
							<a
								variant=''
								href='/login'
								className='border-0 bg-transparent  fs-6 px-5'>
								log in{' '}
								<i class='fa-duotone fa-person-to-portal fa-lg'></i>
							</a>
						</div>
						<motion.div
							initial={{ opacity: 0, scale: 0.5 }}
							animate={{ opacity: 1, scale: 1 }}
							transition={{
								duration: 0.8,
								delay: 0,
								ease: [0, 0.71, 0.2, 1.01],
							}}
							className='mt-2 brand-heading display-6 text-center p-0'>
							Voice Lessons for all Ages!
						</motion.div>
					</Container>

					<Container fluid className='mb-5 mx-0 px-0'>
						<Row className='justify-content-evenly text-center'>
							<Col xs={12} sm={12} md={9} lg={8} xl={7}>
								<Image
									width='100%'
									className='img-responsive'
									src={logo}
									fluid
								/>
							</Col>
						</Row>
						<Row className='m-0'>
							<Col
								xs={6}
								sm={6}
								md={6}
								lg={6}
								className='d-flex justify-content-center'>
								<motion.div
									initial={{ opacity: 0, scale: 0.5 }}
									animate={{ opacity: 1, scale: 1 }}
									transition={{
										duration: 0.8,
										delay: 0.4,
										ease: [0, 0.71, 0.2, 1.01],
									}}>
									<ListGroup className='mt-3 border-0 p-0 bg-transparent fs-6'>
										<ListGroup.Item className='border-0 bg-transparent'>
											private voice lessons
										</ListGroup.Item>
										<ListGroup.Item className='border-0 bg-transparent'>
											group voice lessons
										</ListGroup.Item>
										<ListGroup.Item className='border-0 bg-transparent'>
											improv workshops
										</ListGroup.Item>
										<ListGroup.Item className='border-0 bg-transparent'>
											music literacy
										</ListGroup.Item>
										<ListGroup.Item className='border-0 bg-transparent'>
											audition coaching
										</ListGroup.Item>
									</ListGroup>
								</motion.div>
							</Col>
							<Col
								xs={6}
								sm={6}
								md={6}
								lg={6}
								className='mt-2 p-0 d-md-none'>
								<Image
									width='90%'
									className='p-0 img-responsive'
									src={headline}
									fluid
								/>
							</Col>
							<Col
								xs={6}
								sm={6}
								md={6}
								lg={6}
								className='mt-2 p-0 d-none d-md-block'>
								<Image
									width='60%'
									className='p-0 img-responsive'
									src={headline}
									fluid
								/>
							</Col>
						</Row>
						<motion.div
							initial={{ opacity: 0, scale: 0.5 }}
							animate={{ opacity: 1, scale: 1 }}
							transition={{
								duration: 0.8,
								delay: 1.5,
								ease: [0, 0.71, 0.2, 1.01],
							}}>
							<Col
								xs={12}
								sm={12}
								md={12}
								lg={12}
								className='mt-0 mx-auto'>
								<Row className='justify-content-center text-center'>
									<Col xs={12}></Col>
								</Row>
							</Col>
						</motion.div>
					</Container>
				</Container>
			</Container>
			<Container className='px-0 g-0'>
				<Container className='px-0 g-0 my-2' fluid>
					{/* <SummerAccordionV2 /> */}
				</Container>
				<Container className='px-0 g-0 my-2' fluid>
					<Card
						className='border-0 rounded-0 bg-voiceLesson p-4'
						style={{ minWidth: '19rem' }}
						id='fall23'>
						<div className='border border-dark border-1 bg-light text-center mx-auto p-3'>
							<strong className='bg-transparent mx-0 p-0 bg-transparent display-6'>
								Join Our Waitlist
							</strong>
							<div className='bg-transparent mx-0 p-1 fs-6 bg-transparent'>
								Create your account to get on our waitlist!{' '}
								<br />
								For more information{' '}
								<Link to='/contact'>contact us</Link>
							</div>
						</div>
						<Card.Footer className='bg-transparent border-0 mx-auto my-auto'>
							<Button href='/sign-up'>SIGN UP</Button>
						</Card.Footer>
					</Card>
				</Container>

				<Container fluid className='p-3' id='about'>
					<Faculty />
				</Container>
				<Container fluid className='p-3 background-blue'>
					<WhatYouLearn />
				</Container>
				<Container fluid className='p-3' id='testimonials'>
					<Quotes />
				</Container>
				<Container fluid className='p-3 background-blue' id='contact'>
					<Contact24 />
				</Container>
			</Container>
		</>
	);
};

export default Home;
