import React from 'react';
import { Container } from 'react-bootstrap';
import LoginWidget from './LoginWidget';

const Login = () => {
	return (
		<Container fluid>
			<LoginWidget />
		</Container>
	);
};

export default Login;
