import React from 'react';
import { Container } from 'react-bootstrap';
import ContactWidget from './ContactWidget';

const Login = () => {
	return (
		<Container fluid>
			<Container>
				<h2 className='text-start display-5 p-3 brand-title'>
					contact us
				</h2>
			</Container>
			<ContactWidget />
		</Container>
	);
};

export default Login;
