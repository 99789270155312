import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { Card, Button } from 'react-bootstrap';
import SummerSessions from '../Summer/SummerSessions';
import Summer24 from '../Summer/Summer24';

function CustomToggle({ children, eventKey }) {
	const decoratedOnClick = useAccordionButton(eventKey, () =>
		console.log('totally custom!')
	);

	return (
		<Button
			size='lg'
			type='button'
			variant=''
			className='fw-light bg-transparent border-0 '
			onClick={decoratedOnClick}>
			{children}
		</Button>
	);
}

function SummerAccordionV2() {
	return (
		<Accordion flush defaultActiveKey='1'>
			<Card className='background-summer p-3'>
				<div className='border border-dark border-1 bg-light text-center m-0 py-4 px-0'>
					<strong className='bg-transparent mx-0 p-0 bg-transparent display-5'>
						SUMMER 24'
					</strong>
					<div className='bg-transparent mx-0 p-0 fs-4 fw-light text-body-secondary'>
						Begins Monday June 24<sup>th</sup>
					</div>
					<div className='bg-transparent mx-0 p-0 fs-5 text-body-secondary bg-transparent'>
						available for sign-ups now!
					</div>
				</div>

				<Card.Header className='bg-transparent text-center border-0 p-3 fs-4'>
					<CustomToggle eventKey='0'>
						VIEW PROGRAMMING{' '}
						<i class='fa-solid fa-chevron-down'></i>
					</CustomToggle>
				</Card.Header>
				<Accordion.Collapse eventKey='0'>
					<Card.Body className='bg-transparent d-grid px-0 mx-0 g-0'>
						<Summer24 />
						<div className='text-center '>
							<div
								className='my-2
				'>
								<div className='text-body-secondary text-decoration-none fs-4'>
									<a
										href='/login'
										className='text-decoration-none'>
										login{' '}
										<i class='fa-duotone fa-person-to-portal fa-lg'></i>
									</a>{' '}
									or{' '}
									<a href='/sign-up'>
										sign up{' '}
										<i class='fa-duotone fa-user-plus'></i>
									</a>{' '}
									to register!
								</div>
							</div>
						</div>
					</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
	);
}

export default SummerAccordionV2;
