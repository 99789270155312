import React from 'react';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import ProductAccordion from '../Products/ProductAccordion';
import ProductCards from '../Products/ProductCards';
import { Helmet } from 'react-helmet';

import Summer24 from '../Summer/Summer24';

const SummerSessions = () => {
	const cardStyles = [
		{ borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' },
		{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' },
		{ borderRadius: '30px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' },
		{ borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' },
	];

	return (
		<Container fluid className='py-5 text-center mt-5 background-summer'>
			<Helmet>
				<title>Summer | Color Your Voice</title>
			</Helmet>
			<Card.Title className='border border-dark border-1 bg-light text-center m-0 py-3 px-0 '>
				<strong className='bg-transparent mx-0 p-0 bg-transparent display-4'>
					SUMMER 24'
				</strong>
				<div className='bg-transparent mx-0 p-0 fs-4 fw-light text-body-secondary'>
					Begins Monday June 24<sup>th</sup>
				</div>
				<div className='bg-transparent mx-0 p-0 fs-4 fw-light text-body-secondary'>
					Available for bookings now!
				</div>
				{/* <div
					className='my-2
				'>
					<small className='text-body-secondary text-decoration-none '>
						<a href='/login' className='text-decoration-none'>
							login{' '}
							<i class='fa-duotone fa-person-to-portal fa-lg'></i>
						</a>{' '}
						or{' '}
						<a href='/sign-up'>
							sign up <i class='fa-duotone fa-user-plus'></i>
						</a>{' '}
						to register!
					</small>
				</div> */}
			</Card.Title>

			<Summer24 />

			<div className='d-grid  mt-4 '>
				<div
					className='my-2
				'>
					<div className='text-body-secondary text-decoration-none fs-3'>
						<a href='/login' className='text-decoration-none'>
							login{' '}
							<i class='fa-duotone fa-person-to-portal fa-lg'></i>
						</a>{' '}
						or{' '}
						<a href='/sign-up'>
							sign up <i class='fa-duotone fa-user-plus'></i>
						</a>{' '}
						to register!
					</div>
				</div>
			</div>
		</Container>
	);
};

export default SummerSessions;
