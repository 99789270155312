import React, { useEffect, useRef } from 'react';
import { Container, Card, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const LoginWidget = () => {
	const divRef = useRef(null);

	useEffect(() => {
		const script = document.createElement('script');
		script.src =
			'https://app.mymusicstaff.com/Widget/v4/Widget.ashx?settings=eyJTY2hvb2xJRCI6InNjaF9NTmJKUCIsIldlYnNpdGVJRCI6Indic196ZzlKRCIsIldlYnNpdGVCbG9ja0lEIjoid2JiX21mcUpOIn0=';
		script.async = true;
		if (divRef.current) {
			divRef.current.innerHTML = '';
			divRef.current.appendChild(script);
		}
	}, []);

	return (
		<Container fluid className='bg-primary-white'>
			<Helmet>
				<title>Login | Color Your Voice</title>
			</Helmet>

			<Container
				fluid
				className='mx-auto py-0 px-0 mb-0 mt-3'
				style={{ maxWidth: '365px' }}>
				<div ref={divRef} id='my-music-staff-widget'></div>
			</Container>
			<Container className='py-3'>
				<div className='fs-5 mb-3 text-center'>
					New to Color Your Voice?{' '}
					<a href='/sign-up'>
						sign-up <i class='fa-duotone fa-user-plus'></i>
					</a>
				</div>
			</Container>
		</Container>
	);
};

export default LoginWidget;
