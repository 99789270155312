import { React } from 'react';
import { Card, Container, Button } from 'react-bootstrap';
import Moment from 'react-moment';
import { Helmet } from 'react-helmet';

const Policies = () => {
	return (
		<>
			<Helmet>
				<title>Policies | Color Your Voice</title>
			</Helmet>
			<Container className='my-3 g-0 px-0'>
				<Card className=''>
					<Card.Header className='fs-5 bg-primary-blue text-white p-2'>
						<strong>
							⚠️ SESSION INFORMATION | STUDIO POLICIES | TERMS &
							CONDITIONS ⚠️
						</strong>
						<Card.Subtitle className='text-start p-2'>
							<Moment format='MM/DD/YYYY'></Moment>
						</Card.Subtitle>
					</Card.Header>
					<Card.Body className='p-4'>
						<h3>📅 School Year Duration</h3>
						<ul>
							<li>
								The school year at COLOR YOUR VOICE runs from
								Sunday, August 25th, 2024, to May 18th, 2025.
							</li>
							<li>
								Weekly lessons, rehearsals, and other activities
								will take place within this period.
							</li>
							<li>
								Please plan your schedule accordingly to make
								the most of the full session.
							</li>
						</ul>
						<h3>💵 Tuition Details</h3>
						<p>
							<strong>Flat Rate:</strong> $2,200 for the school
							year, tax included.
						</p>
						<p>
							<strong>Payment Schedule:</strong> Tuition is spread
							across 10 months, with 10 payments of $220 each.
							Payments are scheduled for the 1st of the month,
							beginning September 1st and ending with the last
							payment on June 1st.
						</p>
						<p>
							<strong>Autopay:</strong> All families must be on
							autopay.
						</p>
						<p>
							<strong>Convenience Charge:</strong> There is an
							additional convenience charge when paying by credit
							card online. This charge is not included in the base
							tuition price.
						</p>
						<p>
							<strong>Payment Options:</strong>
						</p>
						<ul>
							<li>
								To avoid the online convenience charge, families
								can pay the full amount upfront by check.
							</li>
							<li>
								Personal checks are not accepted for monthly
								payments.
							</li>
							<li>
								Checks must be handed to either Anthony or Grace
								in person and should not be left at the studio.
							</li>
						</ul>
						<p>
							We do not charge by the lesson during the school
							year; it is a flat rate.
						</p>
						<h3>💳 Payment Methods and Billing</h3>
						<ul>
							<li>
								All payments are processed through our{' '}
								<a href='/login' target='_blank'>
									Parent/Student Portal
								</a>
								.
							</li>
						</ul>
						<h3>🛑 Make-Up & Cancellation Policy</h3>
						<div
							style={{
								fontSize: '18px',
								fontWeight: 'bold',
								color: '#ff6347',
							}}>
							24-Hour Cancellation Policy
						</div>
						<ul>
							<li>
								All lessons require a 24-hour cancellation
								notice.{' '}
								<strong>
									Lessons canceled with less than 24 hours'
									notice are not eligible for a makeup.
								</strong>
							</li>
							<li>
								If you cancel at least 24 hours in advance, you
								will receive a makeup credit. This makeup credit
								will be good to use, provided space is
								available, until May 17th, 2025.
							</li>
							<li>
								<strong>
									Makeup lessons are subject to availability
									and are not guaranteed.
								</strong>
							</li>
						</ul>
						<div
							style={{
								fontSize: '18px',
								fontWeight: 'bold',
								color: '#ff6347',
							}}>
							Closure Due to Snow or Inclement Weather
						</div>
						<ul>
							<li>
								If the studio is closed due to snow or inclement
								weather, the 24-hour cancellation policy is
								waived. You may opt for a makeup credit or
								attend your lesson virtually.
							</li>
							<li>
								If COLOR YOUR VOICE cancels your lesson for any
								other reason, a makeup lesson will be
								guaranteed.
							</li>
						</ul>
						<h3>🚪 Withdrawal Policy</h3>
						<p>
							If a family decides to withdraw from the program
							before the school year is up, we require 30 days'
							notice. This notice period will allow us to make
							necessary adjustments to schedules and plan
							accordingly.
						</p>
						<h3>📅 Scheduled Closed Dates</h3>
						<ul>
							<li>
								All scheduled closures (holidays, spring break,
								performance days, etc.) are listed on the
								calendar in our{' '}
								<a href='/login' target='_blank'>
									Parent/Student Portal
								</a>
								.
							</li>
							<li>
								No makeups are provided for scheduled closures,
								so please consider this when choosing your
								lesson time for the year.
							</li>
						</ul>
						<h3>🩺 Health & Safety</h3>
						<p>
							The health and safety of our students are our top
							priority. Please do not attend lessons if you are
							feeling unwell. We offer virtual lessons as an
							alternative to in-person sessions for those who are
							unable to attend due to illness.
						</p>
						<h3>📸 Media & Privacy</h3>
						<p>
							We occasionally take photos or videos during lessons
							and performances to share on our website and social
							media. If you prefer that your child not be included
							in these media, please notify us in writing.
						</p>
						<h3>📧 Communication</h3>
						<p>
							We primarily communicate via email for updates and
							important information. Please ensure that we have
							your most up-to-date contact information on file.
						</p>{' '}
						<div className='footer text-center'>
							<p className=''>
								If you have any questions please feel free to{' '}
								<a href='/contact'>contact us</a>.
							</p>
							<Button href='/sign-up' className='btn'>
								SIGN UP
							</Button>
						</div>
					</Card.Body>{' '}
				</Card>
			</Container>
		</>
	);
};

export default Policies;
