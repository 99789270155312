import React from 'react';

import { Container, Col, Row, ListGroup, ListGroupItem } from 'react-bootstrap';

export default function WhatYouLearn() {
	return (
		<Container className='g-1 bg-transparent my-5'>
			<h2 className='text-left display-5  p-3 brand-title'>
				what you'll learn
			</h2>

			<Row className='p-5 shadow-sm fs-5 bg-light '>
				<Col xs={12} sm={6} md={6} lg={4} className='p-0 '>
					<p className=' skills text-wrap'>
						{/* <FontAwesomeIcon className='icon' icon={faMusic} size='xs' />{' '} */}
						• vocal strength and versatility
					</p>
				</Col>
				<Col xs={12} sm={6} md={6} lg={4} className='p-0 '>
					<p className=' skills text-wrap'>
						{/* <FontAwesomeIcon className='icon' icon={faMusic} size='xs' />{' '} */}
						• breathing technique
					</p>
				</Col>
				<Col xs={12} sm={6} md={6} lg={4} className='p-0 '>
					<p className=' skills text-wrap'>
						{/* <FontAwesomeIcon className='icon' icon={faMusic} size='xs' /> ear */}
						• ear training
					</p>
				</Col>
				<Col xs={12} sm={6} md={6} lg={4} className='p-0 '>
					<p className=' skills text-wrap'>
						{/* <FontAwesomeIcon className='icon' icon={faMusic} size='xs' />{' '} */}
						• music theory
					</p>
				</Col>
				<Col xs={12} sm={6} md={6} lg={4} className='p-0 '>
					<p className=' skills text-wrap'>
						{/* <FontAwesomeIcon className='icon' icon={faMusic} size='xs' /> mind */}
						• mind and body awareness
					</p>
				</Col>
				<Col xs={12} sm={6} md={6} lg={4} className='p-0 '>
					<p className=' skills text-wrap'>
						{/* <FontAwesomeIcon className='icon' icon={faMusic} size='xs' />{' '} */}
						• articulation
					</p>
				</Col>
				<Col xs={12} sm={6} md={6} lg={4} className='p-0 '>
					<p className=' skills text-wrap'>
						{/* <FontAwesomeIcon className='icon' icon={faMusic} size='xs' />{' '} */}
						• standard American dialect
					</p>
				</Col>
				<Col xs={12} sm={6} md={6} lg={4} className='p-0 '>
					<p className=' skills text-wrap'>
						{/* <FontAwesomeIcon className='icon' icon={faMusic} size='xs' />{' '} */}
						• lyric analysis
					</p>
				</Col>
				<Col xs={12} sm={6} md={6} lg={4} className='p-0 '>
					<p className=' skills text-wrap'>
						{/* <FontAwesomeIcon className='icon' icon={faMusic} size='xs' />{' '} */}
						• character development
					</p>
				</Col>
				<Col xs={12} sm={6} md={6} lg={4} className='p-0 '>
					<p className=' skills text-wrap'>
						{/* <FontAwesomeIcon className='icon' icon={faMusic} size='xs' />{' '} */}
						• repertoire development
					</p>
				</Col>
				<Col xs={12} sm={6} md={6} lg={4} className='p-0 '>
					<p className=' skills text-wrap'>
						{/* <FontAwesomeIcon className='icon' icon={faMusic} size='xs' />{' '} */}
						• audition etiquette & preparation
					</p>
				</Col>
				<Col xs={12} sm={6} md={6} lg={4} className='p-0 '>
					<p className=' skills text-wrap'>
						{/* <FontAwesomeIcon className='icon' icon={faMusic} size='xs' />{' '} */}
						• explore multiple genres
					</p>
				</Col>
			</Row>
		</Container>
	);
}
