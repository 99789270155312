import React from 'react';
import TestimonialCard from './TestimonialCard';
import jess from '../../assets/jessHeadShot.jpg';
import heather from '../../assets/heatherHeadShot.jpg';
import logo from '../../assets/birdIcon.png';
import { Container, Row, Col, Card } from 'react-bootstrap';

const Quotes = () => {
	return (
		<Container className='my-5'>
			<h2 className='text-end display-5 brand-title  p-3 '>
				testimonials
			</h2>
			<Row className='justify-content-evenly'>
				<Col md={12} lg={6}>
					<TestimonialCard
						image={jess}
						alt='Jessica Keenan Wynn'
						quote='When working with Grace, you’ll not only acquire great
				technique, but you’ll gain a mentor, a confidant, and a
				cheerleader. It’s this formula that sets Grace apart from other
				teachers. She will encourage you in ways that will benefit what
				you apply in the classroom and also in life outside of it. If we
				all had a little more Grace in our lives, we’d be much better off!
				I highly and wholeheartedly recommend Grace!'
						author='Jessica Keenan Wynn,'
						credit1='Mamma Mia! Here We Go Again (Legendary Pictures),'
						credit2='Beautiful (Broadway),'
						credit3='Heathers: The Musical (Off-Broadway)'
					/>
				</Col>
				<Col md={12} lg={6}>
					<TestimonialCard
						image={heather}
						alt='Heather Shields Headshot'
						quote='I have worked with Grace in a number of capacities as a producer
					and manager over the years. Beyond her immense talent as a
					performer, for which I have had the pleasure to hire her many
					times, I find myself consistently turning to her as a top-rated
					instructor and coach. I have had the privilege of working with
					several of her students - all of whom are talented, polished, and
					professional. I have also had the privilege to hire Grace as a
					teaching artist for several projects. I know that when Grace is on
					the team, the students she is working with will produce joyful,
					confident, and top-rate performances. I simply cannot recommend
					her enough!'
						author='Heather Shields,'
						credit1='Producer - Bandstand (Broadway),'
						credit2='Producer - A Christmas Carol (Broadway),'
						credit3='Producer - Puffs (Off-Broadway)'
					/>
				</Col>
				<Col sm={12} md={6}>
					<TestimonialCard
						image={logo}
						alt='VVS Logo'
						quote='Grace has given my 14-year-old son the confidence to take risks
					and take his musical theatre journey to the next level. His
					dedication has significantly increased since working with Grace.
					He trusts her and feels comfortable reaching out to her when he
					might need an additional lesson to prepare for an audition or
					show. Lessons via FaceTime have been a lifesaver (both before and
					during pandemic) for us as a family. One less place to run to and
					in the comfort of our home. We are so thankful to have found
					Grace!'
						author='Marisa, Minnesota'
					/>
				</Col>
				<Col sm={12} md={6}>
					<TestimonialCard
						image={logo}
						alt='VVS Logo'
						quote='Grace has been such an amazing person to have the opportunity to
					work with. She approaches everything with such a positive
					attitude, while also maintaining the ability to be educational. I
					have seen so much improvement through working with her, and think
					she’s the best.'
						author='Emma, Texas'
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default Quotes;
