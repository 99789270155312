import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from '../../pages/Home/Home';
import Faculty from '../Faculty/Faculty';
import Testimonials from '../../pages/Testimonials/Testimonials';
import Login from '../../pages/Members/Login';
import SignUp from '../../pages/Members/SignUp';
import Contact24 from '../../pages/Members/Contact24';
import SummerSessions from '../Summer/SummerSessions';
import Summer24 from '../Summer/Summer24';
import Policies from '../../pages/Policies/Policies';

function AnimatedRoutes() {
	const location = useLocation();
	return (
		<Routes location={location} key={location.pathname}>
			<Route path='/' element={<Home />} />
			<Route path='/about-us' element={<Faculty />} />
			<Route path='/testimonials' element={<Testimonials />} />
			<Route path='/sign-up' element={<SignUp />} />
			<Route path='/login' element={<Login />} />
			<Route path='/summer' element={<SummerSessions />} />
			<Route path='/policies' element={<Policies />} />

			<Route path='/contact' element={<Contact24 />} />
			{/* Add other routes here */}
		</Routes>
	);
}

export default AnimatedRoutes;
