import React from 'react';

import {
	Container,
	Card,
	Image,
	Row,
	Col,
	Overlay,
	OverlayTrigger,
	OverlayProps,
	OverlayTriggerProps,
	Popover,
	Button,
} from 'react-bootstrap';

import graceHeadshot from '../../assets/GraceHeadshot.jpeg';
import anthonyHeadshot from '../../assets/ApicellaHeadshot2.png';
import familyFarm from '../../assets/familyFarm.jpeg';
import headlineLogo from '../../assets/HLDlogo.webp';
import faceOffLogo from '../../assets/faceOffLogo.png';

import { Helmet } from 'react-helmet';
import { BackButton } from '../Buttons/BackButtonBlue';

const Faculty = () => {
	return (
		<Container className='my-5'>
			<h2 className='text-end display-5 brand-heading pt-2 pb-5 '>
				our teachers
			</h2>

			<Row className=''>
				<Col sm={12} md={12} lg={5} className='d-flex'>
					<Card.Body className='align-self-top text-center'>
						<Card.Title className=' fs-3 brand-heading text-center '>
							Grace Wall
						</Card.Title>
						<Card.Img
							fluid
							style={{ maxHeight: '280px', maxWidth: '300px' }}
							src={graceHeadshot}
							className='w-100 image-responsive'
						/>
					</Card.Body>
				</Col>
				<Col sm={12} md={12} lg={7} className='d-flex'>
					<Card.Body className='align-self-center '>
						<Card.Text className=''>
							<p className='text-justify fs-5'>
								Grace began her vocal training at the Los
								Angeles County High School for the Arts where
								she trained in opera with Yale graduate and
								opera professional Stephanie Vlahos. She
								continued her training at UCLA, receiving a BA
								in Musical Theatre. After graduating, Grace went
								on to train in Bel Canto with Tony Award-winner
								Victoria Clark. From the Hollywood Bowl to
								Lincoln Center, Paris and London, Grace has
								performed all over the globe. She’s an LA Weekly
								Award-winning actress, and has appeared in
								numerous theatrical productions on both coasts.
								Grace has also had the pleasure of taking part
								in the workshops of several new musicals in NYC
								(including Disney Theatricals' The Hunchback of
								Notre Dame). Television credits include 90210,
								NBC’s The Voice, and National Geographic’s
								Robbery in the Sky. Grace is also working
								towards a Masters degree in Speech Language
								Pathology in order to further apply the bio
								mechanics of speech to the art of singing.
							</p>
						</Card.Text>
					</Card.Body>
				</Col>
			</Row>
			<Row className='justify-content-evenly'>
				<Col
					sm={12}
					md={12}
					lg={4}
					className='d-flex d-block d-xs-block d-sm-block d-md-block d-lg-none'>
					<Card.Body className=' text-center align-self-top'>
						<Card.Title className='p-3 fs-3 brand-heading text-center'>
							Anthony Apicella
						</Card.Title>
						<Card.Img
							fluid
							style={{ maxHeight: '300px' }}
							src={anthonyHeadshot}
							className='w-auto image-responsive'
						/>
					</Card.Body>
				</Col>
				<Col sm={12} md={12} lg={8} className='d-flex'>
					<Card.Body className=' align-self-center'>
						<Card.Text className=''>
							<p className='text-justify fs-5 '>
								Since attending the American Musical and
								Dramatic Academy in NYC, Anthony has traveled
								the world as a performer, gathering a wealth of
								experience. He has performed solo with
								symphonies across the globe in ‘Disney In
								Concert’ and ‘The Music of the Rat Pack’. He has
								also appeared in numerous musical theatre
								productions in and around NYC including “Rock of
								Ages” playing the leading role of Lonny. Anthony
								co-created and is a member of The Copa Boys; a
								trio of singers dedicated to celebrating Jazz,
								The American Songbook and the singers that
								shaped them. Anthony is still performing and
								touring with symphonies. In addition to teaching
								along side Grace, he works as a freelance web
								developer. He received his training at UCLA’s
								Coding Bootcamp. He built and manages Color Your
								Voice’s website and business operations.
							</p>
						</Card.Text>
					</Card.Body>
				</Col>
				<Col
					sm={12}
					md={12}
					lg={4}
					className='d-flex d-none d-xs-none d-sm-none d-md-none d-lg-block '>
					<Card.Body className='p-3 align-self-center text-center'>
						<Card.Title className='fs-3 brand-heading text-center'>
							Anthony Apicella
						</Card.Title>
						<Card.Img
							fluid
							style={{ maxHeight: '300px' }}
							src={anthonyHeadshot}
							className='w-auto image-responsive'
						/>
					</Card.Body>
				</Col>
			</Row>

			<Row className='justify-content-evenly'>
				<Col sm={12} md={12} lg={12}>
					<Card.Title className=' p-3 text-center'>
						<h3 className='fs-1 brand-title'>family business...</h3>
					</Card.Title>
				</Col>
			</Row>
			<Row className='justify-content-center'>
				<Card.Img
					fluid
					style={{ maxHeight: '350px' }}
					src={familyFarm}
					className='w-auto image-responsive'
				/>
				<Col md={12} lg={8} className='d-flex'>
					<Card.Body className='align-self-center'>
						<Card.Text className=''>
							<p className='text-justify fs-5'>
								Grace and Anthony met and fell in love singing
								together at Ellen’s Stardust Diner, a tourist
								destination located in the heart of Times
								Square, NYC. They have been lucky enough to work
								with each other as performers throughout their
								relationship. Their adventures together ranged
								from sharing the stage as entertainers in
								various theatre productions, arenas, concerts,
								cruise ships, piano bars, readings, recordings,
								and cabarets to writing and performing material
								that has been aired on The Howard Stern Show.
								The two studied improv together at NYC’s{' '}
								<a
									href='https://www.faceoffunlimited.com/'
									target='_blank'>
									<Image
										className='drop-shadow h2'
										src={faceOffLogo}
										width='90rem'
										fluid
									/>
								</a>
								. Grace and Anthony also produced and directed
								together for 'The Copa Boys' under legendary
								Conductor / Drummer, Michael Berkowitz. <br />{' '}
								Inspired by the success of dear friends Andrew
								and Enrico ( owners of{' '}
								<a
									className='bold'
									href='https://www.headlinedancecenter.com/'
									target='_blank'
									style={{
										color: 'rgb(227, 72, 162)',
									}}>
									<Image
										className='drop-shadow h2'
										src={headlineLogo}
										width='90rem'
										fluid
									/>
								</a>{' '}
								), they made the journey to Burnsville MN, where
								they run Color Your Voice together and have
								settled down to raise their two year old son
								Archer.
							</p>
						</Card.Text>
					</Card.Body>
				</Col>
			</Row>
		</Container>
	);
};

export default Faculty;
