import React from 'react';
import { Container } from 'react-bootstrap';
import SignUpWidget from './SignUpWidget';

const SignUp = () => {
	return (
		<Container fluid>
			<SignUpWidget />
		</Container>
	);
};

export default SignUp;
