import React, { useState } from 'react';
import { Modal, Button, Card, ListGroup } from 'react-bootstrap';
import logo from '../../assets/LOGOheadline.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faPersonToPortal } from '@fortawesome/pro-duotone-svg-icons';
import SummerAccordionV2 from '../Products/SummerAccordV3';
import SummerAccordV3 from '../Products/SummerAccordV3';
import FallAccord from '../Products/FallAccord';

const SummerModal = (props) => {
	const [show, setShow] = useState(true);
	const handleClose = () => setShow(false);

	return (
		<Modal
			{...props}
			size='md-down'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			show={show}
			onHide={handleClose}
			className='p-0 bg-transparent'>
			<Modal.Header closeButton className='border-0 bg-transparent ' />
			<Modal.Body className='text-center bg-transparent'>
				<Card
					className='border-0 p-0 bg-transparent'
					style={{ maxWidth: '100%' }}>
					{/* <Card.Img
						width='100%'
						className='bg-transparent img-responsive mx-auto'
						src={logo}
						fluid
					/> */}

					<Card.Body className='g-0 mx-0 bg-transparent p-0 '>
						{/* <Button
							onClick={handleClose}
							variant='warning'
							size='sm'
							className='fw-light fs-3 '
							href='/summer'>
							MORE INFO{' '}
							<i class='text-white fa-duotone fa-music'></i>
						</Button> */}
						<FallAccord />
						<div className='text-center '>
							<div
								className='my-2
				'>
								<div className='text-body-secondary text-decoration-none fs-4'>
									<a
										href='/login'
										className='text-decoration-none'>
										login{' '}
										<i class='fa-duotone fa-person-to-portal fa-lg'></i>
									</a>{' '}
									or{' '}
									<a href='/sign-up'>
										sign up{' '}
										<i class='fa-duotone fa-user-plus'></i>
									</a>{' '}
									to register!
								</div>
							</div>
						</div>
					</Card.Body>
				</Card>
			</Modal.Body>
		</Modal>
	);
};

export default SummerModal;
