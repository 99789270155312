import React from 'react';

import { Helmet } from 'react-helmet';

import {
	faChevronRight,
	faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import Quotes from '../../components/Testimonials/Quotes';

import { Container, Card } from 'react-bootstrap';

const Testimonials = () => {
	return (
		<Container className='g-0 my-5'>
			<Helmet>
				<title>Testimonials | Color Your Voice</title>
				<meta
					name='description'
					content='"I know that when Grace is on the team, the students she is working with will produce joyful, confident, and top-rate performances. I simply cannot recommend her enough! " - Heather Shields, Broadway Producer'
				/>
				<meta
					name='keywords'
					content='Voice Lessons, Singing, Singing Teacher, Online Voice Lessons, Voice Teacher, Private Voice Lessons, Grace Wall'
				/>
			</Helmet>

			<Quotes></Quotes>
		</Container>
	);
};

export default Testimonials;
