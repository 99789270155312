import React, { useState } from 'react';
import { Card, Button, ListGroup, Container, Row, Col } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faMicrophone,
	faMusic,
	faVideo,
	faTheaterMasks,
	faGuitar,
	faFilm,
	faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';
import {
	faMicrophoneStand,
	faUserShakespeare,
} from '@fortawesome/pro-duotone-svg-icons';

const Summer24 = () => {
	const offerings = [
		{
			title: '45-Minute Private Lesson',
			icon: faMicrophone,
			description:
				'For voice or acting material, including performance analysis, delivery, and vocal technique.  Sold as single lessons only.',
			color: 'primary-blue',
			buttonText: 'Sign Up / Log In',
			link: '/login',
			items: [],
		},
		{
			title: 'Audition/Special Performance Cut Track',
			icon: faUserShakespeare,
			description:
				'Includes a reference/practice track and a performance track (per song). Call or email to book.',
			color: 'primary-pink',
			buttonText: 'Sign Up / Log In',
			link: '/login',
			items: [],
		},
		{
			title: '1-Hour Coaching/Filming for Audition Videos',
			icon: faVideo,
			description:
				'Ideal for college pre-screens, taped auditions, and competitions. Call or email to book.',
			color: 'primary-orange',
			buttonText: 'Call or Email to Book',
			link: '/contact',
			items: [
				'Studio time',
				'Personalized coaching',
				'Expert editing',
				'Sharable video file',
			],
		},
		{
			title: 'Custom Tailored Audition Material',
			icon: faTheaterMasks,
			description:
				'Includes 2 contrasting monologues, 2 contrasting 32-bar cuts, 2 16-bar cuts, and a 45-minute meeting to discuss specific needs. Call or email to book.',
			color: 'primary-blue',
			buttonText: 'Call or Email to Book',
			link: '/contact',
			items: [],
		},
		{
			title: 'Basic Ukulele',
			icon: faGuitar,
			description:
				'July 8-11. Learn basic ukulele skills with a group over 4 days and celebrate with a small performance on the final day!',
			color: 'primary-pink',
			buttonText: 'Sign Up / Log In',
			link: '/login',
			items: [
				'Ages 8-10 @ 11-11:45',
				'Ages 11-13 @ 12-12:45',
				'Ages 14+ @ 1:00-1:45',
			],
		},
		{
			title: 'Musical Theatre Audition Bootcamp',
			icon: faFilm,
			description:
				'July 15-18. Get ready for your upcoming musical auditions and walk in feeling like a pro.',
			color: 'primary-orange',
			buttonText: 'Sign Up / Log In',
			link: '/login',
			items: ['Ages 11-13 @ 3:45-5:15', 'Ages 14-18 @ 7:00-8:30'],
		},
		{
			title: 'Just the Acting Audition Boot Camp',
			icon: faTheaterMasks,
			description:
				'July 15-18. Get serious about your acting skills and leave with custom picked scenes and monologues.',
			color: 'primary-blue',
			buttonText: 'Sign Up / Log In',
			link: '/login',
			items: ['Ages 11-13 @ 2:00-3:30', 'Ages 14-18 @ 5:30-7:00'],
		},
		{
			title: 'College Audition Arts Prep & Info',
			icon: faGraduationCap,
			description:
				'July 14th, Sunday 4PM. Enjoy a brief presentation from graduate of UCLA musical theatre program and recent graduate of SMU BFA acting program followed by a Q&A.  Find out what you need to prepare and leave with a spreadsheet to keep your journey organized. Break a leg!',
			color: 'primary-pink',
			buttonText: 'SIGN UP / LOG IN to Register',
			link: '/login',
			items: [],
		},
		{
			title: 'Karaoke Sunday Fundays',
			icon: faMicrophoneStand,
			description:
				"Come join us for karaoke in a safe but fun 'artmosphere!' Try new songs every week or bring in your old trusted material!",
			color: 'primary-orange',
			buttonText: 'Sign Up / Log In',
			link: '/login',
			items: [
				'Ages 8-10 @ 3-4PM',
				'Ages 11-13 @ 4-5PM',
				'Ages 14-18 @ 6-7PM',
				'Ages 18+ @ 7-8PM',
			],
		},
	];

	const [hoveredIndex, setHoveredIndex] = useState(null);
	const [toggledIndex, setToggledIndex] = useState(null);

	const handleToggle = (index) => {
		if (toggledIndex === index) {
			setToggledIndex(null);
		} else {
			setToggledIndex(index);
		}
	};

	return (
		<Container className='mt-5 g-0 px-0 mx-auto '>
			<Row className=''>
				{offerings.map((offering, index) => (
					<Col md={6} className='mb-4' key={index}>
						<motion.div
							whileHover={{ scale: 1.05 }}
							onHoverStart={() => setHoveredIndex(index)}
							onHoverEnd={() => setHoveredIndex(null)}
							onClick={() => handleToggle(index)}>
							<Card
								className={`bg-${offering.color} text-`}
								style={{
									borderRadius: '20px',
									boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
								}}>
								<Card.Body className='text-center text-white'>
									{hoveredIndex === index ||
									toggledIndex === index ? (
										<>
											<Card.Title className='text-melon fw-light'>
												{offering.title}
											</Card.Title>
											<Card.Text>
												{offering.description}
											</Card.Text>
											{offering.items.length > 0 && (
												<ListGroup
													variant='flush'
													className={`bg-${offering.color} border-0`}>
													{offering.items.map(
														(item, idx) => (
															<ListGroup.Item
																className={`bg-${offering.color} text-white border-0`}
																key={idx}>
																{item}
															</ListGroup.Item>
														)
													)}
												</ListGroup>
											)}
											<Button
												className='border-1 fw-light'
												variant='warning'
												href={offering.link}>
												<FontAwesomeIcon
													icon={offering.icon}
												/>{' '}
												{offering.buttonText}
											</Button>
										</>
									) : (
										<Card.Title className='text-white fw-light'>
											<FontAwesomeIcon
												icon={offering.icon}
											/>{' '}
											{offering.title}
										</Card.Title>
									)}
								</Card.Body>
							</Card>
						</motion.div>
					</Col>
				))}
			</Row>
		</Container>
	);
};

export default Summer24;
