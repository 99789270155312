import React from 'react'; // Import the Component component from React
import { Card } from 'react-bootstrap';
import { Image, Row, Col, Container } from 'react-bootstrap';
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri';

function TestimonialCard(props) {
	return (
		<>
			<Card className=' border-0 mt-2 bc-4 mx-auto bg-transparent'>
				<div className='p-2 row'>
					<Image
						className='mx-auto p-2 '
						// style={{ backgroundColor: 'var(--dark)' }}
						src={props.image}
						alt={props.alt}
						style={{ width: '10rem' }}
						roundedCircle
					/>

					<blockquote className='blockquote card-body '>
						<div className='position-relative'>
							<span className='fs-3 d-flex justify-content-start'>
								<RiDoubleQuotesL />
							</span>{' '}
							<br />
							<p className='p-0 m-0'>{props.quote}</p> <br />
							<br />
							<span className='fs-3 d-flex justify-content-end'>
								<RiDoubleQuotesR />
							</span>{' '}
						</div>

						<footer className='blockquote-footer quoted'>
							<small className=''>
								{props.author}
								<br />
								<br />
								<cite title='Source Title'>
									{props.credit1}{' '}
								</cite>
								<cite title='Source Title'>
									{props.credit2}{' '}
								</cite>
								<cite title='Source Title'>
									{props.credit3}
								</cite>
							</small>
						</footer>
					</blockquote>
				</div>
			</Card>
		</>
	);
}

export default TestimonialCard;
