import React from 'react';

// react imports
import {
	Navbar,
	Nav,
	NavDropdown,
	Offcanvas,
	Form,
	FormControl,
	Button,
	Container,
	Card,
	Row,
	Image,
} from 'react-bootstrap';

import { Link, NavLink } from 'react-router-dom';

// icon imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebook,
	faInstagram,
	faTwitter,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

// asset imports
import logoBird from '../../assets/LOGOshort.png';
import logoFull from '../../assets/LOGOshort.png';
import ContactUs from '../ContactForm/ContactUs';

const NavbarTop = () => {
	return (
		<>
			<Navbar
				collapseOnSelect
				className=' p-0 d-none d-sm-block bg-transparent '
				id=''
				sticky='top'
				expand={false}>
				<Container fluid>
					<Navbar.Brand href='/'>
						<Image
							width='80px'
							height='auto'
							className='p-2 '
							src={logoBird}
							id='logo'
						/>
					</Navbar.Brand>
					{/* <Card.Title className='mt-2 fs-2 text-right p-2 '>
						Voice Lessons for all Ages!
					</Card.Title> */}
					<Navbar.Toggle
						className='border-0 '
						size='lg'
						aria-controls='offcanvasNavbar'
					/>
					<Navbar.Offcanvas
						className='bg-light'
						id='offcanvasNavbar'
						aria-labelledby='offcanvasNavbarLabel'
						placement='end'>
						<Offcanvas.Header closeButton>
							<Offcanvas.Title id='offcanvasNavbarLabel'>
								<Image
									width='250px'
									height='auto'
									className='text-end '
									src={logoFull}
									id='logo'
								/>
							</Offcanvas.Title>
						</Offcanvas.Header>
						<Offcanvas.Body>
							<Nav className='justify-content-end flex-grow-1 pr-3 brand-title fs-4'>
								<Nav.Link eventKey='1' as={Link} to='/'>
									home
								</Nav.Link>
								<Nav.Link eventKey='1' href='/about-us'>
									about us
								</Nav.Link>
								{/* <Nav.Link
									className='border-0 bg-transparent border-0 '
									eventKey='1'
									as={Link}
									to='/private-lessons'>
									private lessons
								</Nav.Link> */}
								{/* <Nav.Link
									className='border-0 bg-transparent border-0 '
									eventKey='1'
									as={Link}
									to='/classes'>
									classes
								</Nav.Link> */}
								<Nav.Link
									eventKey='1'
									as={Link}
									to='/testimonials'
									href='#testimonials'>
									testimonials
								</Nav.Link>
								<Nav.Link
									className='border-0 bg-transparent border-0 '
									eventKey='1'
									as={Link}
									to='/policies'>
									info | policies
								</Nav.Link>{' '}
								<Nav.Link
									className='border-0'
									eventKey='1'
									as={Link}
									to='/login'>
									login
								</Nav.Link>
								<Nav.Link
									className='border-0'
									eventKey='1'
									as={Link}
									to='/sign-up'>
									sign-up
								</Nav.Link>
								<Nav.Link
									eventKey='1'
									as={Link}
									to='/contact'
									href='contact'>
									contact us
								</Nav.Link>
							</Nav>

							<div className='row mx-auto'>
								<Nav.Link
									href='mailto:info@coloryourvoice.com'
									target='_blank'
									className='col-2 p-3'>
									<FontAwesomeIcon
										className='text-pink'
										icon={faEnvelope}
										size='2x'
									/>
								</Nav.Link>
								<Nav.Link
									href='http://instagram.com/coloryourvoice'
									target='_blank'
									className='col-2 p-3'>
									<FontAwesomeIcon
										className='text-melon'
										icon={faInstagram}
										size='2x'
									/>
								</Nav.Link>
								<Nav.Link
									href='http://twitter.com/coloryourvoice'
									target='_blank'
									className='col-2 p-3'>
									<FontAwesomeIcon
										className='text-yellow'
										icon={faTwitter}
										size='2x'
									/>
								</Nav.Link>
								<Nav.Link
									hre2f='http://facebook.com/coloryourvoiceatheadlinedancecenter'
									target='_blank'
									className='col-2 p-3'>
									<FontAwesomeIcon
										className='text-blue'
										icon={faFacebook}
										size='2x'
									/>
								</Nav.Link>
								<Nav.Link
									href='http://youtube.com/coloryourvoice'
									target='_blank'
									className='col-2 p-3'>
									<FontAwesomeIcon
										className='text-orange'
										icon={faYoutube}
										size='2x'
									/>
								</Nav.Link>
							</div>
						</Offcanvas.Body>
					</Navbar.Offcanvas>
				</Container>
			</Navbar>
			<Navbar
				collapseOnSelect
				className=' d-block d-sm-none bg-transparent'
				id='navbarTop'
				sticky=''
				expand={false}>
				<Container fluid>
					<Navbar.Brand href='/'>
						<Image
							width='80px'
							height='auto'
							className='p-2 '
							src={logoBird}
							id='logo'
						/>
					</Navbar.Brand>
					<Navbar.Toggle
						className='border-0 '
						aria-controls='offcanvasNavbar'
					/>
					<Navbar.Offcanvas
						className=''
						id='offcanvasNavbar'
						aria-labelledby='offcanvasNavbarLabel'
						placement='end'>
						<Offcanvas.Header closeButton>
							<Offcanvas.Title id='offcanvasNavbarLabel'>
								<Image
									width='250px'
									height='auto'
									className='text-end '
									src={logoFull}
									id='logo'
								/>
							</Offcanvas.Title>
						</Offcanvas.Header>
						<Offcanvas.Body>
							<Nav className='justify-content-end flex-grow-1 pe-3 brand-title fs-4 '>
								<Nav.Link eventKey='1' as={Link} to='/'>
									home
								</Nav.Link>
								<Nav.Link eventKey='1' href='/about-us'>
									about us
								</Nav.Link>
								{/* <Nav.Link
									className='border-0 bg-transparent border-0 '
									eventKey='1'
									as={Link}
									to='/private-lessons'>
									private lessons
								</Nav.Link> */}
								{/* <Nav.Link
									className='border-0 bg-transparent border-0 '
									eventKey='1'
									as={Link}
									to='/classes'>
									classes
								</Nav.Link> */}
								<Nav.Link
									eventKey='1'
									as={Link}
									to='/testimonials'
									href='#testimonials'>
									testimonials
								</Nav.Link>
								<Nav.Link
									className='border-0 bg-transparent border-0 '
									eventKey='1'
									as={Link}
									to='/policies'>
									policies
								</Nav.Link>{' '}
								<Nav.Link
									className='border-0'
									eventKey='1'
									as={Link}
									to='/login'>
									login
								</Nav.Link>
								<Nav.Link
									className='border-0'
									eventKey='1'
									as={Link}
									to='/sign-up'>
									sign-up
								</Nav.Link>
								<Nav.Link
									eventKey='1'
									as={Link}
									to='/contact'
									href='contact'>
									contact us
								</Nav.Link>
							</Nav>
							<div className='row mx-auto'>
								<Nav.Link
									href='mailto:info@coloryourvoice.com'
									target='_blank'
									className='col-2 p-3'>
									<FontAwesomeIcon
										className='text-pink'
										icon={faEnvelope}
										size='2x'
									/>
								</Nav.Link>
								<Nav.Link
									href='http://instagram.com/coloryourvoice'
									target='_blank'
									className='col-2 p-3'>
									<FontAwesomeIcon
										className='text-melon'
										icon={faInstagram}
										size='2x'
									/>
								</Nav.Link>
								<Nav.Link
									href='http://twitter.com/coloryourvoice'
									target='_blank'
									className='col-2 p-3'>
									<FontAwesomeIcon
										className='text-yellow'
										icon={faTwitter}
										size='2x'
									/>
								</Nav.Link>
								<Nav.Link
									hre2f='http://facebook.com/coloryourvoiceatheadlinedancecenter'
									target='_blank'
									className='col-2 p-3'>
									<FontAwesomeIcon
										className='text-blue'
										icon={faFacebook}
										size='2x'
									/>
								</Nav.Link>
								<Nav.Link
									href='http://youtube.com/coloryourvoice'
									target='_blank'
									className='col-2 p-3'>
									<FontAwesomeIcon
										className='text-orange'
										icon={faYoutube}
										size='2x'
									/>
								</Nav.Link>
							</div>
						</Offcanvas.Body>
					</Navbar.Offcanvas>
				</Container>
			</Navbar>
		</>
	);
};

export default NavbarTop;
