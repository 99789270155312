import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { Card, Button } from 'react-bootstrap';
import SummerSessions from '../Summer/SummerSessions';
import Summer24 from '../Summer/Summer24';

function CustomToggle({ children, eventKey }) {
	const decoratedOnClick = useAccordionButton(eventKey, () =>
		console.log('totally custom!')
	);

	return (
		<Button
			size='lg'
			type='button'
			variant=''
			className='fw-light bg-transparent border-0 '
			onClick={decoratedOnClick}>
			{children}
		</Button>
	);
}

function FallAccord() {
	return (
		<Accordion flush defaultActiveKey='1'>
			<Card className='background-fall p-3'>
				<div className='border border-dark border-1 bg-light text-center m-0 py-3 px-0'>
					<strong className='bg-transparent mx-0 p-0 bg-transparent display-5'>
						FALL 24'
					</strong>
					<div className='bg-transparent mx-0 p-0 fs-4 fw-light text-body-secondary'>
						{/* Begins Monday June 24<sup>th</sup> */}
						Semester begins Sunday, August 25th
					</div>
					<div className='bg-transparent mx-0 p-2 fs-6 text-body-secondary bg-transparent'>
						If you are already on our wailist, you will be hearing
						from us soon about any available spots.{' '}
						<div className='py-2'>STAY TUNED!</div>
					</div>
				</div>
			</Card>
		</Accordion>
	);
}

export default FallAccord;
