import React from 'react';

// react imports

// icon imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faInstagram,
	faYoutube,
	faFacebook,
	faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

// asset imports

import { Col, Row, Card, Container } from 'react-bootstrap';
import ScrollToTop from '../ScrollUp/ScrollToButton';

const NavbarBottom = () => {
	return (
		<Container className=' border-top' sticky='bottom' expand=''>
			<Row className='mt-4'>
				<Col xs={12}>
					<div className='mx-5 p-3 text-muted text-center small'>
						Phone: (952) 283-0560 <br></br>
						12004 12th Ave S<br />
						Burnsville, MN 55337
					</div>
				</Col>
				<Col xs={12}>
					<div className='mx-5 p-3  text-center text-muted small'>
						Copyright &copy; {new Date().getFullYear()}
						&nbsp;Color Your Voice LLC | All Rights Reserved | site
						by{' '}
						<a href='https://anthonyapps.com/'>anthonyapps.com</a>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default NavbarBottom;
