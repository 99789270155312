import React from 'react';

import {
	Card,
	ListGroup,
	ListGroupItem,
	Image,
	Container,
	Button,
	Badge,
} from 'react-bootstrap';

import { BiMusic } from 'react-icons/bi';
import RecordingSessionModal from '../Modals/RecordingSession/SummerSessionModal';

function ProductCards(props) {
	return (
		<Card
			style={{ maxWidth: '30rem' }}
			className='bg-transparent border-0 mt-2 mb-5'>
			<Card.Img
				variant='top'
				alt={props.alt}
				src={props.image}
				style={{ width: '195px' }}
				className='mx-auto mb-2'
				fluid
			/>
			<Card.Img
				variant='top'
				alt={props.alt}
				src={props.image2}
				style={{ width: '115px' }}
				className='mx-auto mb-2'
				fluid
			/>
			<Card.Header className=' border-0 p-3'>
				<Card.Title className='p-0'>
					<h6 className=''>
						<div className='d-flex justify-content-end'>
							<Badge bg='danger' className=''>
								{props.badge}
							</Badge>
						</div>
						<span className='fs-1'>{props.title} </span>{' '}
					</h6>
				</Card.Title>
				<Card.Subtitle className='pb-1 text-muted fs-4'>
					{props.subtitle}
				</Card.Subtitle>
			</Card.Header>
			<Card.Body className='bg-transparent p-1'>
				<Card.Text className='p-1 fs-6'>{props.description}</Card.Text>
				<Card.Text className='fs-6 bg-transparent border-0 px-2'>
					<small>{props.info}</small>
					<br />
					<small>{props.days}</small>
					<br />
					<small>{props.times}</small>
					<br />
					<small>{props.dates}</small>
				</Card.Text>
				<div className='p-1'>{props.ages}</div>
			</Card.Body>
			<Card.Footer className='small bg-transparent border-0'>
				{props.book}
			</Card.Footer>
			{/* <RecordingSessionModal /> */}
		</Card>
	);
}

export default ProductCards;
